
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as add06KPcpc0YTx_45gBefPvJF8GNqhv_45_45dJEHxXABSKmD44MMeta } from "/app/pages/admin/faq-categories/add.vue?macro=true";
import { default as _91id_93sf_dfhX2fMlNT1VpgHEqWvygIKBdgeVQhbog17sVg5MMeta } from "/app/pages/admin/faq-categories/edit/[id].vue?macro=true";
import { default as indexlWm_45uWcGEDAOs58FDutvdoNXw6YnoNcS_Zatl6Ppc8kMeta } from "/app/pages/admin/faq-categories/index.vue?macro=true";
import { default as adddTe_45DvDBsbQPtQTBeHY6PROM9gM3vES38tog4tEyKVoMeta } from "/app/pages/admin/faq/add.vue?macro=true";
import { default as _91id_93IKHHTBOL4hzIQagR6I14xhFhhypsA_45A2jE1k_45d0wn_45UMeta } from "/app/pages/admin/faq/edit/[id].vue?macro=true";
import { default as indexDGBIUS1JlkGxmdP_45P9y1CHBS3vsMjS425W94_45SJzqawMeta } from "/app/pages/admin/faq/index.vue?macro=true";
import { default as indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta } from "/app/pages/admin/index.vue?macro=true";
import { default as addWbk_45ucjVcr3_cnuBD7owkDImYYVUFElV2P0Rlx2dug0Meta } from "/app/pages/admin/mobile-plan-types/add.vue?macro=true";
import { default as _91id_93H38dROB0_45kvkyrG23MRPpI78PgpT3hxVMe086Y7HvioMeta } from "/app/pages/admin/mobile-plan-types/edit/[id].vue?macro=true";
import { default as index8tzPXzY34_45k9_45v55QxbmsM7KdOT_45VA72dnnapzbbD8AMeta } from "/app/pages/admin/mobile-plan-types/index.vue?macro=true";
import { default as addRIr_45vdrIkk31iG_bx_GRsned4nDMPGj8IlxSh7CN30AMeta } from "/app/pages/admin/mobile-plans/add.vue?macro=true";
import { default as _91id_93qMIUpwSdYgVwJQAXfZhXVhw1rQtzxynnVMo3jpjrgX0Meta } from "/app/pages/admin/mobile-plans/edit/[id].vue?macro=true";
import { default as indexK6o2QF4bJTDHcJJ4ujWnuGv9_45fCRR3Odua31_45zCUvZoMeta } from "/app/pages/admin/mobile-plans/index.vue?macro=true";
import { default as add_fuQNlyTZVtBkoPSuQmbJDj5kQbLN0jXH7u7_OClg9MMeta } from "/app/pages/admin/news-categories/add.vue?macro=true";
import { default as _91id_93CCDlX0ZeGnNRik6akdsolkkBMh0t5H8N72wLAf_45OoCUMeta } from "/app/pages/admin/news-categories/edit/[id].vue?macro=true";
import { default as indexsucYHmwLYMWFesdP7gzHFpBULlumdsYwFbWu7R45S9MMeta } from "/app/pages/admin/news-categories/index.vue?macro=true";
import { default as addC8GLsGmoQ30PgFwp2nK580th1AHzsOD7gtJu0U8v2g8Meta } from "/app/pages/admin/news/add.vue?macro=true";
import { default as _91id_938rHJOJTFe18lOgMWf31MUBrBYu9a3LQLTf93R6K2QG0Meta } from "/app/pages/admin/news/edit/[id].vue?macro=true";
import { default as index6PEoyQDaDnGaVzaCd6HlDTns54GHlWn02QbaMXpVbRoMeta } from "/app/pages/admin/news/index.vue?macro=true";
import { default as loginHOGV2v2PINdTIHc7UC2kEvGCEOmL3_45VnFH_Nuw9GeeMMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registerP6kYPRY6AO3o2k9aPJs1KTHwpt17Hy4EvBog_g8SDPYMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as verify_45otpBdfx2Y2hxcdSF9dLyVJls4w6Vl8k3ECAqz2Lkrv7PzwMeta } from "/app/pages/auth/verify-otp.vue?macro=true";
import { default as darkove_45voucheryUVn0zDFk5rWAPqRjv_wGn5jbkEEfTmeJWX1EYTwjSIEMeta } from "/app/pages/darkove-vouchery.vue?macro=true";
import { default as dotazycmWXZz1GohmW3TqpHL4nI_45KKQ0_4567aOPsNmpiwGWRZIMeta } from "/app/pages/dotazy.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as internetF_45vECiqWVyG_45wYXJ_tuxPHtxYzdta5w5NdbsjK0t0PYMeta } from "/app/pages/internet.vue?macro=true";
import { default as kontaktyJt0_OLi8SikPbHSZkLC8SQsNRcU30sX_KHE7rOf5QksMeta } from "/app/pages/kontakty.vue?macro=true";
import { default as kosikE2R6fkgduHjMbe2_45PG1lMhFivGZVqnXyf_3SXQMMbj4Meta } from "/app/pages/kosik.vue?macro=true";
import { default as mobilpKkS78HArGW3EPNGgxqIjcOXMhOLr6Rp2qkw0QI_45IRYMeta } from "/app/pages/mobil.vue?macro=true";
import { default as ochrana_45osobnich_45udajuWEegD9MuFWYj5L2ZzDg8Ym3fqyr4DFCYB99MtDXXdqsMeta } from "/app/pages/ochrana-osobnich-udaju.vue?macro=true";
import { default as herni_45serverywQ6D_nCywALcP_45ygj1S_cT7BamO43Ov8HQfYzi8gnkYMeta } from "/app/pages/sluzby/herni-servery.vue?macro=true";
import { default as indexQzSzDclCuQWTol0l88miP_45GScLXAcOXuJ6Pt2LMm1JIMeta } from "/app/pages/sluzby/index.vue?macro=true";
import { default as lteB8714BWGnoib0N_Eg29FxYtATy2a5cfGswEK3EjInM0Meta } from "/app/pages/sluzby/lte.vue?macro=true";
import { default as routery_45a_45zarizeni5ZC5Ljh_NG8gfLn6qmydF7AzGyEohmwvslADH6WLvk4Meta } from "/app/pages/sluzby/routery-a-zarizeni.vue?macro=true";
import { default as voipvmoVklijXW53T8hca4z3p3G5x0eJf9w_454fF8WLLq14AMeta } from "/app/pages/sluzby/voip.vue?macro=true";
import { default as vpsA8lmUZrqM1o2GueypahHNI9vuZ2Lg5ylJ5HmGcHsRdkMeta } from "/app/pages/sluzby/vps.vue?macro=true";
import { default as webhostingq4JjPKO7hVXDzqy0yzNo2FcejclaMk7SzKpC106_6bYMeta } from "/app/pages/sluzby/webhosting.vue?macro=true";
import { default as okutKcUs_45kTUESHGGDA33XTc5ix9JpoGiqlXDqIORMUQJ4Meta } from "/app/pages/stranka/oku.vue?macro=true";
import { default as tabulka_45rychlostiC_45acq5QUvWy1S7aUVJI6zWgX_xsvSUNGZnK_454VExCbIMeta } from "/app/pages/tabulka-rychlosti.vue?macro=true";
import { default as televizeOVCbmgdU3x_rGPdynYm08E1MP4UGOuYgnnvSpGcJlD0Meta } from "/app/pages/televize.vue?macro=true";
export default [
  {
    name: "admin-faq-categories-add",
    path: "/admin/faq-categories/add",
    meta: add06KPcpc0YTx_45gBefPvJF8GNqhv_45_45dJEHxXABSKmD44MMeta || {},
    component: () => import("/app/pages/admin/faq-categories/add.vue")
  },
  {
    name: "admin-faq-categories-edit-id",
    path: "/admin/faq-categories/edit/:id()",
    meta: _91id_93sf_dfhX2fMlNT1VpgHEqWvygIKBdgeVQhbog17sVg5MMeta || {},
    component: () => import("/app/pages/admin/faq-categories/edit/[id].vue")
  },
  {
    name: "admin-faq-categories",
    path: "/admin/faq-categories",
    meta: indexlWm_45uWcGEDAOs58FDutvdoNXw6YnoNcS_Zatl6Ppc8kMeta || {},
    component: () => import("/app/pages/admin/faq-categories/index.vue")
  },
  {
    name: "admin-faq-add",
    path: "/admin/faq/add",
    meta: adddTe_45DvDBsbQPtQTBeHY6PROM9gM3vES38tog4tEyKVoMeta || {},
    component: () => import("/app/pages/admin/faq/add.vue")
  },
  {
    name: "admin-faq-edit-id",
    path: "/admin/faq/edit/:id()",
    meta: _91id_93IKHHTBOL4hzIQagR6I14xhFhhypsA_45A2jE1k_45d0wn_45UMeta || {},
    component: () => import("/app/pages/admin/faq/edit/[id].vue")
  },
  {
    name: "admin-faq",
    path: "/admin/faq",
    meta: indexDGBIUS1JlkGxmdP_45P9y1CHBS3vsMjS425W94_45SJzqawMeta || {},
    component: () => import("/app/pages/admin/faq/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-mobile-plan-types-add",
    path: "/admin/mobile-plan-types/add",
    meta: addWbk_45ucjVcr3_cnuBD7owkDImYYVUFElV2P0Rlx2dug0Meta || {},
    component: () => import("/app/pages/admin/mobile-plan-types/add.vue")
  },
  {
    name: "admin-mobile-plan-types-edit-id",
    path: "/admin/mobile-plan-types/edit/:id()",
    meta: _91id_93H38dROB0_45kvkyrG23MRPpI78PgpT3hxVMe086Y7HvioMeta || {},
    component: () => import("/app/pages/admin/mobile-plan-types/edit/[id].vue")
  },
  {
    name: "admin-mobile-plan-types",
    path: "/admin/mobile-plan-types",
    meta: index8tzPXzY34_45k9_45v55QxbmsM7KdOT_45VA72dnnapzbbD8AMeta || {},
    component: () => import("/app/pages/admin/mobile-plan-types/index.vue")
  },
  {
    name: "admin-mobile-plans-add",
    path: "/admin/mobile-plans/add",
    meta: addRIr_45vdrIkk31iG_bx_GRsned4nDMPGj8IlxSh7CN30AMeta || {},
    component: () => import("/app/pages/admin/mobile-plans/add.vue")
  },
  {
    name: "admin-mobile-plans-edit-id",
    path: "/admin/mobile-plans/edit/:id()",
    meta: _91id_93qMIUpwSdYgVwJQAXfZhXVhw1rQtzxynnVMo3jpjrgX0Meta || {},
    component: () => import("/app/pages/admin/mobile-plans/edit/[id].vue")
  },
  {
    name: "admin-mobile-plans",
    path: "/admin/mobile-plans",
    meta: indexK6o2QF4bJTDHcJJ4ujWnuGv9_45fCRR3Odua31_45zCUvZoMeta || {},
    component: () => import("/app/pages/admin/mobile-plans/index.vue")
  },
  {
    name: "admin-news-categories-add",
    path: "/admin/news-categories/add",
    meta: add_fuQNlyTZVtBkoPSuQmbJDj5kQbLN0jXH7u7_OClg9MMeta || {},
    component: () => import("/app/pages/admin/news-categories/add.vue")
  },
  {
    name: "admin-news-categories-edit-id",
    path: "/admin/news-categories/edit/:id()",
    meta: _91id_93CCDlX0ZeGnNRik6akdsolkkBMh0t5H8N72wLAf_45OoCUMeta || {},
    component: () => import("/app/pages/admin/news-categories/edit/[id].vue")
  },
  {
    name: "admin-news-categories",
    path: "/admin/news-categories",
    meta: indexsucYHmwLYMWFesdP7gzHFpBULlumdsYwFbWu7R45S9MMeta || {},
    component: () => import("/app/pages/admin/news-categories/index.vue")
  },
  {
    name: "admin-news-add",
    path: "/admin/news/add",
    meta: addC8GLsGmoQ30PgFwp2nK580th1AHzsOD7gtJu0U8v2g8Meta || {},
    component: () => import("/app/pages/admin/news/add.vue")
  },
  {
    name: "admin-news-edit-id",
    path: "/admin/news/edit/:id()",
    meta: _91id_938rHJOJTFe18lOgMWf31MUBrBYu9a3LQLTf93R6K2QG0Meta || {},
    component: () => import("/app/pages/admin/news/edit/[id].vue")
  },
  {
    name: "admin-news",
    path: "/admin/news",
    meta: index6PEoyQDaDnGaVzaCd6HlDTns54GHlWn02QbaMXpVbRoMeta || {},
    component: () => import("/app/pages/admin/news/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginHOGV2v2PINdTIHc7UC2kEvGCEOmL3_45VnFH_Nuw9GeeMMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerP6kYPRY6AO3o2k9aPJs1KTHwpt17Hy4EvBog_g8SDPYMeta || {},
    component: () => import("/app/pages/auth/register.vue")
  },
  {
    name: "auth-verify-otp",
    path: "/auth/verify-otp",
    meta: verify_45otpBdfx2Y2hxcdSF9dLyVJls4w6Vl8k3ECAqz2Lkrv7PzwMeta || {},
    component: () => import("/app/pages/auth/verify-otp.vue")
  },
  {
    name: "darkove-vouchery",
    path: "/darkove-vouchery",
    component: () => import("/app/pages/darkove-vouchery.vue")
  },
  {
    name: "dotazy",
    path: "/dotazy",
    component: () => import("/app/pages/dotazy.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "internet",
    path: "/internet",
    component: () => import("/app/pages/internet.vue")
  },
  {
    name: "kontakty",
    path: "/kontakty",
    component: () => import("/app/pages/kontakty.vue")
  },
  {
    name: "kosik",
    path: "/kosik",
    component: () => import("/app/pages/kosik.vue")
  },
  {
    name: "mobil",
    path: "/mobil",
    component: () => import("/app/pages/mobil.vue")
  },
  {
    name: "ochrana-osobnich-udaju",
    path: "/ochrana-osobnich-udaju",
    component: () => import("/app/pages/ochrana-osobnich-udaju.vue")
  },
  {
    name: "sluzby-herni-servery",
    path: "/sluzby/herni-servery",
    component: () => import("/app/pages/sluzby/herni-servery.vue")
  },
  {
    name: "sluzby",
    path: "/sluzby",
    component: () => import("/app/pages/sluzby/index.vue")
  },
  {
    name: "sluzby-lte",
    path: "/sluzby/lte",
    component: () => import("/app/pages/sluzby/lte.vue")
  },
  {
    name: "sluzby-routery-a-zarizeni",
    path: "/sluzby/routery-a-zarizeni",
    component: () => import("/app/pages/sluzby/routery-a-zarizeni.vue")
  },
  {
    name: "sluzby-voip",
    path: "/sluzby/voip",
    component: () => import("/app/pages/sluzby/voip.vue")
  },
  {
    name: "sluzby-vps",
    path: "/sluzby/vps",
    component: () => import("/app/pages/sluzby/vps.vue")
  },
  {
    name: "sluzby-webhosting",
    path: "/sluzby/webhosting",
    component: () => import("/app/pages/sluzby/webhosting.vue")
  },
  {
    name: "stranka-oku",
    path: "/stranka/oku",
    component: () => import("/app/pages/stranka/oku.vue")
  },
  {
    name: "tabulka-rychlosti",
    path: "/tabulka-rychlosti",
    component: () => import("/app/pages/tabulka-rychlosti.vue")
  },
  {
    name: "televize",
    path: "/televize",
    component: () => import("/app/pages/televize.vue")
  }
]