export function useConsent() {
  const consentDone = useCookie('consentDone', {
    default: () => false,
  })

  const acceptedCookies = useCookie('grapescCookies', {
    default: () => false,
  })

  return {
    consentDone,
    acceptedCookies,
  }
}
