<script lang="ts" setup>
import { Toaster } from 'vue-sonner'

useSeoMeta({
  title: 'Úvod',
  description:
    'GRAPE SC, a.s. - internet, televize, volání či LTE - Žatec, Podbořany, Louny, Postoloprty, Chomutov, Jirkov a přilehlé okolní obce',
  titleTemplate: '%s | GRAPE SC, a.s.',
})

const {
  public: { analyticsId, metaPixelId },
} = useRuntimeConfig()

const { acceptedCookies } = useConsent()

useScriptGoogleAnalytics({
  id: analyticsId,
  scriptOptions: {
    trigger: acceptedCookies,
  },
})

useScriptMetaPixel({
  id: metaPixelId,
  scriptOptions: {
    trigger: acceptedCookies,
  },
})

const { loggedIn } = useUserSession()

watch(loggedIn, () => {
  if (!loggedIn.value) {
    navigateTo('/')
  }
})

const storedColorMode = useLocalStorage('nuxt-color-mode', 'light', {
  initOnMounted: true,
})

const colorMode = useColorMode()

onMounted(() => {
  // Force light mode
  storedColorMode.value = 'light'
  colorMode.value = 'light'
})
</script>

<template>
  <div>
    <UiNotifications />
    <Toaster position="top-center" />
    <ConsentBanner />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
