<script setup lang="ts">
const props = defineProps<{
  type?: 'primary' | 'secondary' | 'accent'
  outline?: boolean
  size?: 'sm' | 'md' | 'lg'
  class?: string
}>()

const base = 'whitespace-nowrap rounded-full flex justify-center items-center '
const outline = 'ring-1 ring-inset ring-border '
const primary =
  'bg-sky-600 font-medium text-white '
const secondary =
  'bg-secondary hover:bg-secondary/80 active:bg-secondary/60 text-secondary-contrast font-medium '
const accent =
  'hover:bg-neutral-100 active:bg-neutral-200 text-accent-contrast font-medium dark:hover:bg-neutral-800 '

const types = {
  primary: primary,
  secondary: secondary,
  accent: accent,
}

const sizes = {
  sm: 'px-2 py-1  text-sm gap-1 ',
  md: 'px-5 py-2 text-base gap-2 ',
  lg: 'px-10 py-4 text-lg gap-3 ',
}

const cls = computed(
  () =>
    `${base}${props.outline ? outline : ''}${types[props.type || 'primary']}${sizes[props.size || 'md']}`
)
</script>

<template>
  <button :class="cn(cls, props.class)">
    <slot />
  </button>
</template>
