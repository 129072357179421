import revive_payload_client_7QeDeNuInaEwKvtscBInQlIkRrFmEtHtl1Aix3l8LDs from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bA3hsaT1b9IyOdJVLSnwOAK1GN2IyfwH9HUsjrDIA7U from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5g3eeBG8EBjQlc_q9_zIF3dHb37F7tpOOmRaIz_pCRU from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t4N_187zNlODGCRm4ve07tuYXaxTQ8wkf9Ao7y0NycM from "/app/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_Andw5Rj_bpCo13BaQgvFEtzOjj5WU6Pk3QvDoCgFLjo from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client__TP2p2cO0gJ6px96o7NQb_u0J8O6nPJqGL6sdt5FIdA from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lYMg2N_egNcKW29HV6VLJFMvLhh_fE5z6kVpFx9IOS0 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GRRqyuW6vp6kgLD6jgqBwSQBY9GrG_KVLwcyTpHT5d8 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Sez4EbUNn48Y6PDOhO69kWMjFisHFjXVSCB85ilVsHo from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_@upstash+redis@1.34.5_db0@0.3.1_drizzl_gqtahqqainxzullhzdxe35kx6q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_Unsk6xu7RFqKVO0D60U8W_inj4ZFwK918Go6TKRiwVc from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.3_magicast@0.3.5_rollup@4.35.0_unhead@2.0.0-rc.10_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_ROsqnHhN9CMRxJ1KVCKuB5LZXZBKeEJtIqPH0_JAbIg from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.3_magicast@0.3.5_rollup@4.35.0_unhead@2.0.0-rc.10_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_vZdV_LCsKd2_DcVhbXkpBONo37Z7L6xHe752P46V7PU from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.3_magicast@0.3.5_rollup@4.35.0_unhead@2.0.0-rc.10_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import slideovers_EnPx6dMTTcWdqlni1GEch7nL4moWyzPpUyI_HYwhYZE from "/app/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@types+node@22.1_nypj4zdwesimk5rpfgjf6yzzju/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FaVXutCMoZhiuq0Nvft7sCJqVsOn_OANAk0kENE4bdw from "/app/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@types+node@22.1_nypj4zdwesimk5rpfgjf6yzzju/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_i1Tmo_L_a6FJcsnZ9kI2Tf_PR6uLEqsgHPvVb9WX3mE from "/app/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.1_@types+node@22.1_nypj4zdwesimk5rpfgjf6yzzju/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_UuUTMh_OqdiERujimQnyvY04sEbbzn7YgV_ugLCr4sw from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.1_@types+node@22.13.10_jiti@2.4.2_terser@5.39.0_yam_veh5sjyuaalqitexewhix7zr2e/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import session_client_kBn_6BKpORxp46P9Jvu3WzEpeW3wBJdlouy7aPyj5F8 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.16_magicast@0.3.5/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import defaults_OeduSQxiJJidoFIrRzGamJofptZvU4oBxjHOVloX99Q from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.3_magicast@0.3.5_rollup@4.35.0_unhead@2.0.0-rc.10_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_7QeDeNuInaEwKvtscBInQlIkRrFmEtHtl1Aix3l8LDs,
  unhead_bA3hsaT1b9IyOdJVLSnwOAK1GN2IyfwH9HUsjrDIA7U,
  router_5g3eeBG8EBjQlc_q9_zIF3dHb37F7tpOOmRaIz_pCRU,
  _0_siteConfig_t4N_187zNlODGCRm4ve07tuYXaxTQ8wkf9Ao7y0NycM,
  payload_client_Andw5Rj_bpCo13BaQgvFEtzOjj5WU6Pk3QvDoCgFLjo,
  navigation_repaint_client__TP2p2cO0gJ6px96o7NQb_u0J8O6nPJqGL6sdt5FIdA,
  check_outdated_build_client_lYMg2N_egNcKW29HV6VLJFMvLhh_fE5z6kVpFx9IOS0,
  chunk_reload_client_GRRqyuW6vp6kgLD6jgqBwSQBY9GrG_KVLwcyTpHT5d8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_Sez4EbUNn48Y6PDOhO69kWMjFisHFjXVSCB85ilVsHo,
  siteConfig_Unsk6xu7RFqKVO0D60U8W_inj4ZFwK918Go6TKRiwVc,
  inferSeoMetaPlugin_ROsqnHhN9CMRxJ1KVCKuB5LZXZBKeEJtIqPH0_JAbIg,
  titles_vZdV_LCsKd2_DcVhbXkpBONo37Z7L6xHe752P46V7PU,
  slideovers_EnPx6dMTTcWdqlni1GEch7nL4moWyzPpUyI_HYwhYZE,
  modals_FaVXutCMoZhiuq0Nvft7sCJqVsOn_OANAk0kENE4bdw,
  colors_i1Tmo_L_a6FJcsnZ9kI2Tf_PR6uLEqsgHPvVb9WX3mE,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_UuUTMh_OqdiERujimQnyvY04sEbbzn7YgV_ugLCr4sw,
  session_client_kBn_6BKpORxp46P9Jvu3WzEpeW3wBJdlouy7aPyj5F8,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  defaults_OeduSQxiJJidoFIrRzGamJofptZvU4oBxjHOVloX99Q
]