<script setup lang="ts">
import type { Icon } from '~/types/Icons'

const props = defineProps<{
  icon?: Icon
  materialIcon?: string
}>()
</script>

<template>
  <div
    class="relative gap-5 rounded-xl bg-white p-5"
    :class="props.icon || props.materialIcon ? 'mt-14 pt-14' : ''"
  >
    <div
      v-if="props.icon"
      class="absolute -top-10 left-1/2 flex size-20 -translate-x-1/2 items-center justify-center rounded-full border-4 border-white bg-red-600"
    >
      <img :src="'/img/' + props.icon + '.svg'" class="size-12 text-white" />
    </div>
    <div
      v-else-if="props.materialIcon"
      class="absolute -top-10 left-1/2 flex size-20 -translate-x-1/2 items-center justify-center rounded-full border-4 border-white bg-red-600"
    >
      <Icon :name="props.materialIcon" class="size-12 text-white" />
    </div>
    <slot />
  </div>
</template>
