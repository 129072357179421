<script lang="ts" setup>
const { consentDone, acceptedCookies } = useConsent()

function accept() {
  consentDone.value = true
  acceptedCookies.value = true
}

function decline() {
  consentDone.value = true
  acceptedCookies.value = false
}
</script>

<template>
  <div
    v-if="!consentDone"
    class="fixed bottom-10 left-1/2 z-50 -translate-x-1/2 rounded-3xl border border-neutral-200 bg-neutral-50 p-10"
  >
    <div class="mb-6">
      <p class="mb-2 text-xl font-bold">Tato stránka používá cookies.</p>
      <NuxtLink class="block" to="/ochrana-osobnich-udaju">
        Zásady ochrany osobních údajů
      </NuxtLink>
    </div>
    <div class="flex gap-2">
      <UButton class="text-lg" @click="accept">Povolit</UButton>
      <UButton class="text-lg" variant="ghost" @click="decline">
        Odmítnout
      </UButton>
    </div>
  </div>
</template>
