<script lang="ts" setup>
const error = useError()

function handleError() {
  clearError({
    redirect: '/',
  })
}
</script>

<template>
  <div
    class="grid min-h-screen place-content-center overflow-hidden bg-white p-10 text-black antialiased dark:bg-black dark:text-white"
  >
    <img
      class="pointer-events-none h-40 max-w-full select-none"
      src="/img/logo.svg"
    />
    <div class="my-10 flex flex-col items-center justify-center">
      <p v-if="error.statusCode === 404" class="text-4xl font-bold">
        Stránka nenalezena
      </p>
      <p v-else class="text-4xl font-bold">Něco se pokazilo</p>
      <div v-if="error" class="">
        <h1 v-if="error.statusCode" class="text-7xl font-bold">
          {{ error.statusCode }}
        </h1>
      </div>
    </div>
    <div
      class="bg-primary-600 mx-auto flex w-fit cursor-pointer rounded-xl px-4 py-2 text-white"
      @click="handleError"
    >
      <p class="text-pragmatica-extended text-xl">Zpět na domovskou stránku</p>
    </div>
  </div>
</template>
